<style lang="scss" scoped>
.live-warp {
  background: url(../../assets/images/live/index_bg1.jpg) no-repeat center center / cover;
  height: 100%;
  .copywriting{
    .info{
      position: fixed;
      z-index: 1;
      top: 0px;
      left: 12vw;
      bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      h1{
        font-size: 48px;
        margin-bottom: 22px;
        font-weight: 700;
      }
      h2{
        font-size: 30px;
        font-weight: 100;
      }
      .buttons{
        margin-top: 90px;
        .btn{
          color: #FFF;
          font-size: 26px;
          margin-right: 30px;
          padding: 20px 40px;
          border-radius: 40px;
          cursor: pointer;
        }
        .btn1{
          background: linear-gradient( to right,#00C9FE,#6FE1FF);
        }
        .btn2{
          background: linear-gradient( to right,#FF7E00,#FFB063);
        }
      }

    }
  }
}
</style>
<template>
  <div class="live-warp">
    <topMenu :top="5" />
    <div class="copywriting">
      <div class="info">
        <div>
          <h1>{{ $t('live.home.title')}}</h1>
          <h1>{{ $t('live.home.subtitle')}}</h1>
        </div>
        <div>
          <h2>{{ $t('live.home.content')}}</h2>
        </div>
        <div>
          <div class="buttons">
            <button class="btn btn1" @click="goPage">{{ $t('live.home.settled')}}</button>
            <button class="btn btn2" @click="$router.push({ path: '/upload' })">{{ $t('live.home.download')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// app_key sign timestamp
import topMenu from "@/components/live/topMenu.vue";
export default {
  components: {
    topMenu,
  },
  data() {
    return {
      currentId: 1,
      listName: []
    };
  },
  created: function () {},
  mounted: function () {
    window.addEventListener('mousewheel', this.debounce(this.handleScroll,300), true)||window.addEventListener("DOMMouseScroll",this.debounce(this.handleScroll,300),false)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    debounce(func, wait) {
        let timeout;
        return function () {
            let context = this;
            let args = arguments;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(context, args)
            }, wait);
        }
    },
    handleScroll(e){
        let direction = e.deltaY > 0 ? 'down' : 'up'
        console.log(direction)
        //业务操作
        // this.changeSel(this.currentId); 
    },
    goPage(){
      window.location.href=this.$liveplatformBasePath
    }
  },
  
};
</script>